import * as React from "react"
import { Link } from "gatsby"
import './index.css'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from '../components/Form'
import Testimonials from '../components/Testimonials'

import arrow from '../images/arrow.svg'
import service1 from '../images/services-image1.jpg'
import service2 from '../images/services-image2.jpg'
import service3 from '../images/services-image3.jpg'
import profilePhoto from '../images/kevin.jpg'
import phoneIcon from '../images/phone-icon.svg'

const IndexPage = () => (
  <Layout>
    <SEO title="Glen Burnie Maryland CPA" />
    <section>
      <div className='hero-section'>
        <div className='hero-left'>
          <p>Kevin Rich, CPA</p>
          <h1>Professional Accounting and Tax Services</h1>
          <p>Your partner in financial accounting, reporting, and tax compliance</p>
          <Link id='appointment-link' to='/md-cpa/'>schedule an appointment</Link>
          <a id='call-link' href='tel:4102718259'>
            <img src={phoneIcon} alt='telphone number of accountant in Pasadena Maryland'/>
            <p>call (410) 271-8259</p>
          </a>
        </div>
        <div className='hero-right'>
          <Form />
        </div>
      </div>
    </section>

    <section>
      <div className='section-row'>
        <div className='intro-section-left'>
          <h3 style={{color: `#B62020`}}>CERTIFIED PUBLIC ACCOUNTANT</h3>
          <p>Hello, I'm Kevin,
            <br/>
            <br/>
            A practicing Certified Public Accountant with over 25 years of 
            experience providing professional services to individuals and business in various industries.  
            I work with local individuals and businesses and am committed to their ongoing success. 
          </p>
          <div className='link-container'>
            <Link id='circular-link' to='/maryland-cpa/'>
              <img style={{height: `30px`, width: `30px`}} src={arrow} alt='arrow icon'/>
            </Link>
            <p>ABOUT ME</p>
          </div>
        </div>
        <div className='intro-section-right'>
          <div className='profile-photo-container'>
            <img src={profilePhoto} alt='Keving Rich an accountant in Glen Burnie Maryland' />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className='section-column'>
        <h3 style={{color: `#B62020`}}>SERVING ANNE ARUNDEL COUNTY AND BEYOND</h3>
        <p>
          I'm known for my client-first approach to accounting. That’s why I prioritize in-depth consultations 
          with all my clients. When you contact me, I’ll set up a confidential, obligation-free meeting with 
          you at your earliest convenience. Then, I’ll work closely with you to make sure you’re getting what 
          you want out of my services. I seek to clearly understand your financial objectives so that I can 
          provide you with the accounting solutions that will set you up for success. Whether you need 
          assistance for tomorrow or advice for a goal ten years from now, I will cater your service to suit your unique needs.
          <br/>
          <br/>
          <span style={{fontWeight: `bold`}}>Servicing the greater Baltimore metro area including:  Anne Arundel County, Prince George’s County, Howard County, Baltimore County, and Baltimore City</span>
        </p>
      </div>
    </section>

    <section>
      <div className='services-section'>
        <div className='services-section-top'>
          <p>SERVICES</p>
        </div>
        <div className='services-section-bottom'>
          <div className='individual-service'>
            <div className='individual-service-top'>
              <div className='individual-service-photo-container'>
                <img src={service1} alt='fix this alt'/>
              </div>
              <p style={{margin: `0`}}>Accounting</p>
            </div>
            <div className='individual-service-center'>
              <p>
                Accounting is the process of recording financial transactions pertaining 
                to a business. The accounting process includes summarizing, analyzing 
                and reporting these transactions to oversight agencies, regulators and 
                tax collection entities.
                <br/>
                <br/>
                I can replace your back office with accounting, payroll, and bookkeeping 
                support. When it comes to complex issues, I am a trusted partner.
              </p>
            </div>
            <div className='individual-service-bottom'>
              <Link id='circular-link' to='/services/cpa-columbia-md'>
              <img style={{height: `30px`, width: `30px`}} src={arrow} alt='arrow icon'/>
              </Link>
              <p>DETAILS</p>
            </div>
          </div>
          <div className='individual-service'>
            <div className='individual-service-top'>
              <div className='individual-service-photo-container'>
                <img src={service2} alt='fix this alt'/>
              </div>
              <p style={{margin: `0`}}>Bookkeeping</p>
            </div>
            <div className='individual-service-center'>
                <p>
                  Bookkeeping is the process of keeping track of every financial 
                  transaction made by a business firm from the opening of the firm 
                  to the closing of the firm. Depending on the type of accounting 
                  system used by the business, each financial transaction is recorded 
                  based on supporting documentation. 
                  <br/>
                  <br/>
                  I can help with all of your business bookkeeping needs.
                </p>
            </div>
            <div className='individual-service-bottom'>
              <Link id='circular-link' to='/services/accurate-accounting-glen-burnie'>
              <img style={{height: `30px`, width: `30px`}} src={arrow} alt='arrow icon'/>
              </Link>
              <p>DETAILS</p>
            </div>
          </div>
          <div className='individual-service'>
            <div className='individual-service-top'>
              <div className='individual-service-photo-container'>
                <img src={service3} alt='fix this alt'/>
              </div>
              <p style={{margin: `0`}}>Professional Courtesy</p>
            </div>
            <div className='individual-service-center'>
              <p>Free tax return check-up - I will review your prior tax returns and advise you of any errors</p>
              <p>Discount on first-year tax preparation fees.</p>
            </div>
            <div className='individual-service-bottom'>
              <Link id='circular-link' to='/services/cpa-annapolis-md'>
              <img style={{height: `30px`, width: `30px`}} src={arrow} alt='arrow icon'/>
              </Link>
              <p>DETAILS</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
    <Testimonials />
    </section>

    <section>
    <div className='section-column'>
        <h3 style={{color: `#B62020`}}>PROFESSIONAL ACCOUNTANT AT YOUR SERVICE</h3>
        <p>
        One of the biggest reasons to hire an accountant is because of the savings we offer you in terms of time and accuracy. It 
        could take you many hours to do your own taxes but an accountant has a process he/she repeat hundreds of times during tax 
        season. The tax laws and tax-savings opportunities are fresh in our minds because it’s our primary focus—all year.
        You can also save money on your accountant’s fee by gathering all your financial data together throughout the year. 
        Contributions, all income sources, business expenses (if you’re self-employed), if you pull it all together prior to tax-return 
        time, your accountant will need less time to work their magic.
        <br/>
        <br/>
        In addition, you can even consult your accountant throughout the year for questions regarding gift taxes, tax incentives for 
        hybrid vehicles, paying down debt, and other financial questions. Your accountant can be a trusted financial advisor—and 
        I can’t think of a better investment than that!
        </p>
      </div>
    </section>

    <section>
      <div className='contact-section'>
        <div className='contact-section-top'>
          <p style={{margin: `0`}}>CONTACT</p>
        </div>
        <div className='contact-section-bottom'>
          <div className='contact-section-bottom__left'>
            <p>
            Contact me by phone at <span style={{color: `#B62020`}}>(410)271-8259</span> 
            <br/>
            <br/>
            or
            <br/>
            <br/>
            Fill out the contact form and I'll reach out to you as soon as possible.
            </p>
          </div>
          <div className='contact-section-bottom__right'>
            <Form />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
