import React, { useState } from 'react'
import './Testimonials.css'

import photo1 from '../images/testimonial-photo-1.jpeg'
import photo2 from '../images/testimonial-photo-2.png'
import photo3 from '../images/testimonial-photo-3.jpeg'


const data = [
    {
        photo: photo1,
        testimonial: `Kevin has helped us complete our taxes for the last several years. Our financial situation has gotten more and more complex so we are grateful that he can sort through and prepare our taxes accurately and efficiently. He is always available to answer my many questions and keeps us on track with estimated payments throughout the year.`,
        person: `Lynne L.`
    },
    {
        photo: photo2,
        testimonial: `Kevin is super easy going and a pleasure to work with. He ensures you are up to date and well informed in all of your financial ventures and is always ready to answer any questions or concerns you may have. I would definitely recommend working with Kevin for all of your accounting needs.`,
        person: `David V.`
    },
    {
        photo: photo3,
        testimonial: `I've had Kevin Rich as my accountant for over 10 years. He's very personable and knowledgeable about every aspect of the tax law as it pertains to my business and is timely in answering any questions I may have. I feel confident that everything is done correctly, which takes a huge burden off of my shoulders.`,
        person: `Virginia R.`
    }
]

const Testimonials = () => {
    const [state, setState] = useState(0)

    const handlePrevious = () => {
        setState(state - 1)
        if (state === 0) {
            setState(2)
        }
    }

    const handleNext = () => {
        setState(state + 1)
        if (state === 2) {
            setState(0)
        }
    }
    
    return (
    <div className='testimonials-container'>
        <div className='testimonials-top'>
            <p style={{margin: `0`}}>TESTIMONIALS</p>
            <a className='google-my-business-review-link' href='https://g.page/r/CciOFP27-Td0EBI/review' target="_blank" rel="noopener noreferrer">leave a review</a>
        </div>
        <div className='testimonials-center'>
            <div className='testimonials-center__left'>
                <div className='testimonial-image'>
                    <img src={data[state].photo} alt='Testimonial of Kevin Rich, an accountant in Pasadena Maryland'/>
                </div>
            </div>
            <div className='testimonials-center__right'>
                <p>
                {data[state].testimonial}
                <br/>
                <br/>
                -{data[state].person}
                </p>
            </div>
        </div>
        <div className='testimonials-buttons'>
            <div id='prev-button' onClick={handlePrevious}>
                <p>prev</p>
            </div>
            <div id='next-button' onClick={handleNext}>
                <p>next</p>
            </div>
        </div>
        <div className='testimonials-bottom'>
            <div style={{backgroundColor: state === 0 ? `#000` : `transparent`}} className='pagination-dot'></div>
            <div style={{backgroundColor: state === 1 ? `#000` : `transparent`}} className='pagination-dot'></div>
            <div style={{backgroundColor: state === 2 ? `#000` : `transparent`}} className='pagination-dot'></div>
        </div>
    </div>
    )
}

export default Testimonials